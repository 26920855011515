'use client'

import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useIsClient, useMediaQuery } from 'usehooks-ts'

import HeroBanner from '@/app/HeroBanner'
import BannerDots from '@/app/BannerDots'
import BannerGallery, { BannerGalleryRef } from '@/app/BannerGallery'
import { CarouselApi } from '@/components/ui/carousel'
import { ActivityShowcaseList } from '@/services/cms'
import { useIsPageVisible } from '@/hooks/useIsPageVisible'
import { HeroBannerProvider } from '@/context/HeroBannerContext'
import { useUAContext } from '@/context/UAContext'
import { cn } from '@/lib/utils'
import { useLayoutContext } from '@/context/LayoutContext'

type HeroBannerGalleryProps = {
  lists: ActivityShowcaseList[]
}

const HeroBannerGallery = memo(function HeroBannerGallery({ lists }: HeroBannerGalleryProps) {
  const [api, setApi] = useState<CarouselApi>()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)

  const mainRef = useRef<HTMLDivElement>(null)
  const carouselRef = useRef<HTMLDivElement>(null)
  const galleryRef = useRef<BannerGalleryRef>(null)

  const { headerHeight } = useLayoutContext()
  const { browserName, deviceType } = useUAContext()
  const isClient = useIsClient()
  const isPageVisible = useIsPageVisible()
  const isEdgeDesktop = browserName === 'edge' && deviceType === 'desktop'

  const matchesMd = useMediaQuery('(min-width: 768px)')
  const isMobileLandscape = useMediaQuery('(orientation: landscape) and (max-height: 480px)')
  const isLooping = matchesMd || isMobileLandscape

  useEffect(() => {
    if (!api) return

    setCurrentIndex(api.selectedScrollSnap())

    api.on('select', () => {
      const index = api.selectedScrollSnap()
      setCurrentIndex(index)
      galleryRef.current?.scrollToItem(index)
    })
  }, [api, currentIndex, lists])

  const handleGoTo = useCallback(
    (index: number) => {
      if (!api) return
      api.scrollTo(index, !isLooping)
    },
    [api, isLooping],
  )

  const handleNext = useCallback(() => {
    const nextIndex = currentIndex + 1
    const index = nextIndex >= lists.length ? 0 : nextIndex
    handleGoTo(index)
  }, [lists.length, currentIndex, handleGoTo])

  return (
    <HeroBannerProvider
      lists={lists}
      isPageVisible={isPageVisible}
      isMobileLandscape={isMobileLandscape}
      isLooping={isLooping}
      currentIndex={currentIndex}
      currentProgress={currentProgress}
      setCurrentProgress={setCurrentProgress}
      handleNext={handleNext}
      handleGoTo={handleGoTo}
    >
      <main
        ref={mainRef}
        className={cn('relative flex w-dvw bg-background', {
          '1912:px-[80px] 1912:py-12': isEdgeDesktop,
          'px-[9.6px] py-2': isMobileLandscape,
          'py-9 md:px-[34px] md:py-8 lg:px-[64px] lg:py-8 3xl:px-[80px] 3xl:py-12':
            !isMobileLandscape,
        })}
        style={{
          marginTop: headerHeight,
        }}
      >
        <section
          className={cn('relative flex h-auto w-full flex-1 items-center justify-center', {
            '1912:mb-[22px] 1912:gap-4 1912:px-[177px]': isEdgeDesktop,
            'flex-row justify-center gap-4': isMobileLandscape,
            'md:flex-col md:justify-start md:gap-4 lg:flex-row lg:justify-center lg:gap-6 1.5xl:mb-[46px] 3xl:mb-[22px] 3xl:gap-4 3xl:px-[177px]':
              !isMobileLandscape,
          })}
        >
          <HeroBanner ref={carouselRef} setApi={setApi} />
          {/** 🥷🏼 use isClient to prevent hydration error */}
          {isClient && isLooping && (
            <BannerGallery ref={galleryRef} carouselRef={carouselRef} mainRef={mainRef} />
          )}
          {!isMobileLandscape && <BannerDots />}
        </section>
      </main>
    </HeroBannerProvider>
  )
})

export default HeroBannerGallery
