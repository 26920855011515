import { useEffect, useState } from 'react'

export const useVideoThumbnail = (
  videoUrl: string | undefined,
  disabled = false,
  time: number = 1,
) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null)
  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!videoUrl || disabled) {
      setIsLoadingThumbnail(false)
      return
    }

    const video = document.createElement('video')
    video.src = videoUrl
    video.crossOrigin = 'anonymous'

    const handleLoadedData = () => {
      video.currentTime = time
    }

    const handleSeeked = () => {
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight

      const ctx = canvas.getContext('2d')
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        const imageDataUrl = canvas.toDataURL('image/jpeg')
        setThumbnail(imageDataUrl)
        setIsLoadingThumbnail(false)
      }
    }

    const handleError = () => {
      setError('Failed to load video')
      setIsLoadingThumbnail(false)
    }

    video.addEventListener('loadeddata', handleLoadedData)
    video.addEventListener('seeked', handleSeeked)
    video.addEventListener('error', handleError)

    return () => {
      video.src = ''
      video.removeEventListener('loadeddata', handleLoadedData)
      video.removeEventListener('seeked', handleSeeked)
      video.removeEventListener('error', handleError)
    }
  }, [videoUrl, time, disabled])

  return { thumbnail, isLoadingThumbnail, error }
}
