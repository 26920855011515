import { cva } from 'class-variance-authority'
import { useHeroBannerContext } from '@/context/HeroBannerContext'

const bannerTitleVariants = cva('transition-all duration-1250', {
  variants: {
    variant: {
      image: 'header-2 line-clamp-2 break-words lg:text-[38px] 1.5xl:text-[64px] lg:font-bold',
      video: 'md:header-4 md:w-[140px] header-3 break-words flex',
      gallery: 'lg:subheader-1 body-2',
    },
  },
  defaultVariants: {
    variant: 'image',
  },
})

const bannerTitleMobileLandScapeVariants = cva('transition-all duration-1250', {
  variants: {
    variant: {
      image: 'header-2 line-clamp-2 break-words text-[20px] font-bold',
      video: 'header-4 text-[18px] w-[105px] break-words flex',
      gallery: 'lg:subheader-1 body-2',
    },
  },
  defaultVariants: {
    variant: 'image',
  },
})

type BannerTitleProps = {
  title: string
  title_html?: string
  variant: 'image' | 'video' | 'gallery'
}

function BannerTitle({ title, title_html, variant }: BannerTitleProps) {
  const { isMobileLandscape } = useHeroBannerContext()

  if (variant === 'gallery') {
    if (title_html) {
      const text = title_html?.replace(/<[^>]+>|&[^;]+;/g, '')
      return (
        <p
          className={
            isMobileLandscape
              ? bannerTitleMobileLandScapeVariants({ variant })
              : bannerTitleVariants({ variant })
          }
        >
          {text}
        </p>
      )
    } else {
      return (
        <p
          className={
            isMobileLandscape
              ? bannerTitleMobileLandScapeVariants({ variant })
              : bannerTitleVariants({ variant })
          }
        >
          {title}
        </p>
      )
    }
  }

  if (title_html) {
    return (
      <p
        className={
          isMobileLandscape
            ? bannerTitleMobileLandScapeVariants({ variant })
            : bannerTitleVariants({ variant })
        }
        dangerouslySetInnerHTML={{ __html: title_html }}
      />
    )
  }

  return (
    <p
      className={
        isMobileLandscape
          ? bannerTitleMobileLandScapeVariants({ variant })
          : bannerTitleVariants({ variant })
      }
    >
      {title}
    </p>
  )
}

export default BannerTitle
