'use client'

import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext } from 'react'
import { ActivityShowcaseList } from '@/services/cms'

type HeroBannerContextType = {
  lists: ActivityShowcaseList[]
  isPageVisible: boolean
  isMobileLandscape: boolean
  isLooping: boolean
  currentIndex: number
  currentProgress: number
  setCurrentProgress: Dispatch<SetStateAction<number>>
  handleNext: () => void
  handleGoTo: (index: number) => void
}

const HeroBannerContext = createContext<HeroBannerContextType | null>(null)

export function HeroBannerProvider({
  children,
  lists,
  isPageVisible,
  isMobileLandscape,
  isLooping,
  currentIndex,
  currentProgress,
  setCurrentProgress,
  handleNext,
  handleGoTo,
}: PropsWithChildren<HeroBannerContextType>) {
  return (
    <HeroBannerContext.Provider
      value={{
        lists,
        isPageVisible,
        isMobileLandscape,
        isLooping,
        currentIndex,
        currentProgress,
        setCurrentProgress,
        handleNext,
        handleGoTo,
      }}
    >
      {children}
    </HeroBannerContext.Provider>
  )
}

export const useHeroBannerContext = () => {
  const value = useContext(HeroBannerContext)

  if (value == null) {
    throw new Error('useHeroBannerContext cannot be used outside of HeroBannerProvider')
  }

  return value
}
