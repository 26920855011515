import { useEffect, useRef, useState } from 'react'

import { useHeroBannerContext } from '@/context/HeroBannerContext'
import { ActivityShowcaseList } from '@/services/cms'

type BannerVideoProps = {
  item: ActivityShowcaseList
  isActive: boolean
}

function BannerVideo({ item, isActive }: BannerVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoDuration, setVideoDuration] = useState(0)

  const { isPageVisible, isLooping, setCurrentProgress, handleNext } = useHeroBannerContext()

  useEffect(() => {
    const video = videoRef.current

    if (video && isLooping) {
      // 🥷🏼 force video load to get meta correctly
      video.load()
    }
  }, [isLooping])

  useEffect(() => {
    const video = videoRef.current

    if (video && isPageVisible) {
      video.pause()

      if (isActive) {
        video.currentTime = 0
        video.play()
      }
    }
    // 🥷🏼 add isLooping as dependency here as workaround
    // 🥷🏼 for landscape resizing video suspense issue
  }, [isActive, isPageVisible, isLooping])

  function handleVideoEnd() {
    if (!isActive) return
    handleNext()
  }

  function handleLoadedMetadata() {
    if (!isLooping) return
    setVideoDuration(videoRef.current?.duration || 0)
  }

  function handleTimeUpdate() {
    const video = videoRef.current
    if (!isLooping || !video || !videoDuration) return

    const progress = Number(((video.currentTime / videoDuration) * 100).toFixed(2))
    setCurrentProgress(progress)
  }

  return (
    <video
      ref={videoRef}
      className="absolute inset-0 size-full object-cover"
      muted
      playsInline
      onEnded={handleVideoEnd}
      onTimeUpdate={handleTimeUpdate}
      onLoadedMetadata={handleLoadedMetadata}
    >
      {item.video!.webm && <source src={item.video!.webm} type="video/webm" />}
      {item.video!.mp4 && <source src={item.video!.mp4} type="video/mp4" />}
    </video>
  )
}

export default BannerVideo
