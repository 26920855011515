'use client'

import { useRouter } from 'next/navigation'

import { useHeroBannerContext } from '@/context/HeroBannerContext'
import { CarouselItem } from '@/components/ui/carousel'
import { ActivityShowcaseList } from '@/services/cms'
import { cn } from '@/lib/utils'
import BannerVideo from '@/app/BannerVideo'
import BannerVideoInfo from '@/app/BannerVideoInfo'
import BannerImageInfo from '@/app/BannerImageInfo'
import BannerImage from '@/app/BannerImage'

type BannerItemProps = {
  item: ActivityShowcaseList
  index: number
  total: number
}

function BannerItem({ item, index, total }: BannerItemProps) {
  const router = useRouter()
  const { currentIndex, isLooping, isMobileLandscape, handleGoTo } = useHeroBannerContext()
  const isActive = currentIndex === index

  function handleActionLinkClick(actionLink: string) {
    const currentDomain = window.location.origin
    if (actionLink.startsWith(currentDomain)) {
      router.push(actionLink)
    } else {
      window.open(actionLink, '_blank')
    }
  }

  function handleBannerItemClick() {
    if (!isLooping && !isActive) handleGoTo(index)
  }

  return (
    <CarouselItem
      key={item.title}
      className={cn('relative overflow-hidden rounded-2xl bg-cover bg-center pl-0 md:basis-full', {
        'basis-full': isMobileLandscape,
        'basis-[calc(100dvw-109px)] md:basis-full': !isMobileLandscape,
        'max-md:ml-3': !isMobileLandscape && index !== 0,
        'max-md:ml-6': !isMobileLandscape && index === 0,
        'max-md:mr-6': !isMobileLandscape && index === total - 1,
      })}
      onClick={handleBannerItemClick}
    >
      {item.video ? (
        <BannerVideo item={item} isActive={isActive} />
      ) : (
        <BannerImage item={item} isActive={isActive} />
      )}

      {item.video ? (
        <BannerVideoInfo item={item} isActive={isActive} onClick={handleActionLinkClick} />
      ) : (
        <BannerImageInfo item={item} isActive={isActive} onClick={handleActionLinkClick} />
      )}
    </CarouselItem>
  )
}

export default BannerItem
