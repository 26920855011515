import { useTranslations } from 'next-intl'

import BannerDescription from '@/app/BannerDescription'
import BannerTitle from '@/app/BannerTitle'
import ViverseButton from '@/components/ViverseButton'
import { ActivityShowcaseList } from '@/services/cms'
import { cn } from '@/lib/utils'
import { useHeroBannerContext } from '@/context/HeroBannerContext'

type BannerVideoInfoProps = {
  item: ActivityShowcaseList
  isActive: boolean
  onClick: (link: string) => void
}

function BannerVideoInfo({ item, isActive, onClick }: BannerVideoInfoProps) {
  const t = useTranslations()
  const { isMobileLandscape } = useHeroBannerContext()
  return (
    <div
      className={cn('absolute bottom-0 flex w-full items-center justify-center', {
        'opacity-50': !isActive,
        'opacity-100': isActive,
        'bottom-2 px-2': isMobileLandscape,
        'max-md:transition-all max-md:duration-300 md:bottom-6 md:px-6': !isMobileLandscape,
      })}
    >
      <div
        className={cn(
          'flex flex-1 items-center gap-4 rounded-2xl bg-hero-card-description-mobile p-4',
          {
            'flex-row bg-[#212A3933] bg-none backdrop-blur-[100px]': isMobileLandscape,
            'flex-col md:flex-row md:bg-[#212A3933] md:bg-none md:backdrop-blur-[100px]':
              !isMobileLandscape,
          },
        )}
      >
        <div
          className={cn('flex flex-1 flex-col items-start gap-4', {
            'flex-row items-center': isMobileLandscape,
            'md:flex-row md:items-center': !isMobileLandscape,
          })}
        >
          <BannerTitle title={item.title} title_html={item.title_html} variant="video" />
          <BannerDescription
            description={item.description}
            description_html={item.description_html}
            variant="video"
          />
        </div>
        {item.action_link && (
          <div
            className={cn('flex w-full flex-col items-center justify-center gap-[10px] md:w-auto', {
              'max-w-[186px]': isMobileLandscape,
            })}
          >
            <ViverseButton
              size="filter"
              variant="default"
              className={cn('w-full', {
                'min-h-[48px] whitespace-normal px-[20px] py-3 text-[18px]': isMobileLandscape,
                'h-[34px] md:w-fit md:!min-w-fit 1.5xl:h-[48px] 1.5xl:px-[20px] 1.5xl:py-3 1.5xl:text-lg':
                  !isMobileLandscape,
              })}
              onClick={() => onClick(item.action_link)}
            >
              {item.action_title}
            </ViverseButton>
            {item.enable_no_signup_hint && (
              <div className="hidden text-xs text-white md:block">
                {t('homepage.banner.cta_tips')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default BannerVideoInfo
