'use client'

import { forwardRef } from 'react'
import Fade from 'embla-carousel-fade'

import { Carousel, CarouselApi, CarouselContent } from '@/components/ui/carousel'
import { useHeroBannerContext } from '@/context/HeroBannerContext'
import BannerItem from '@/app/BannerItem'
import { useUAContext } from '@/context/UAContext'
import { cn } from '@/lib/utils'

type HeroBannerProps = {
  setApi: (api: CarouselApi) => void
}

const HeroBanner = forwardRef<HTMLDivElement, HeroBannerProps>(function HeroBanner(
  { setApi },
  ref,
) {
  const { lists, isLooping, isMobileLandscape } = useHeroBannerContext()
  const { browserName, deviceType } = useUAContext()
  const isEdgeDesktop = browserName === 'edge' && deviceType === 'desktop'

  return (
    <Carousel
      ref={ref}
      className={cn('group relative w-full [&>div]:h-full [&>div]:rounded-2xl', {
        '1912:aspect-[1142/576]': isEdgeDesktop,
        'aspect-[640/320] h-auto': isMobileLandscape,
        'aspect-[390/500] h-full md:aspect-[700/400] md:h-auto 1.5xl:aspect-[1040/560] 3xl:aspect-[1142/576]':
          !isMobileLandscape,
      })}
      opts={{
        align: 'center',
        duration: 60,
        loop: isLooping ? true : false,
        skipSnaps: isLooping ? false : true,
      }}
      setApi={setApi}
      plugins={[
        Fade({
          active: isLooping,
        }),
      ]}
    >
      <CarouselContent className="ml-0 h-full">
        {lists.map((item, index, array) => (
          <BannerItem key={item.title} total={array.length} item={item} index={index} />
        ))}
      </CarouselContent>
    </Carousel>
  )
})

export default HeroBanner
